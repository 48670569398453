// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-biomechanics-js": () => import("./../../../src/pages/biomechanics.js" /* webpackChunkName: "component---src-pages-biomechanics-js" */),
  "component---src-pages-cv-tsx": () => import("./../../../src/pages/cv.tsx" /* webpackChunkName: "component---src-pages-cv-tsx" */),
  "component---src-pages-elements-js": () => import("./../../../src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-generic-js": () => import("./../../../src/pages/generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-hri-js": () => import("./../../../src/pages/hri.js" /* webpackChunkName: "component---src-pages-hri-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-js": () => import("./../../../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-machinelearning-js": () => import("./../../../src/pages/machinelearning.js" /* webpackChunkName: "component---src-pages-machinelearning-js" */),
  "component---src-pages-other-js": () => import("./../../../src/pages/other.js" /* webpackChunkName: "component---src-pages-other-js" */),
  "component---src-pages-prosthetics-js": () => import("./../../../src/pages/prosthetics.js" /* webpackChunkName: "component---src-pages-prosthetics-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-teaching-js": () => import("./../../../src/pages/teaching.js" /* webpackChunkName: "component---src-pages-teaching-js" */)
}

